import Service, { inject as service } from '@ember/service';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import { endpoint } from 'secondstreet-common/utils/url';
import type SessionService from './-private/session';
import type CurrentService from './current';
import DefaultFormatService from './default-format';

export type ReportType =
  | 'QuizEntryPicks'
  | 'RegisteredUsers'
  | 'Signups'
  | 'SubscribedUsers'
  | 'SweepstakesEntries'
  | 'Votes'
  | 'Winners'
  | 'MediaDownloadRequests'
  | 'WinningEntrants'
  | 'MessagingStatisticsOrganizations'
  | 'MessagingStatisticsEmails'
  | 'OrganizationPerformance'
  | 'PromotionPerformance'
  | 'OrganizationPromotionPerformance'
  | 'MessagingStatisticsDaily'
  | 'WrapperImport'
  | 'SyndicationDetails'
  | 'EmbedURL';

type Options = {
  params?: Record<string, string | number | boolean>;
  data?: Record<string, unknown>;
};

type Report = {
  file_url: string;
};

export default class ReportsService extends Service {
  @service declare current: CurrentService;
  @service declare enums: EnumsService;
  @service declare session: SessionService;
  @service declare defaultFormat: DefaultFormatService;

  // For easier mocking during tests
  window = window;

  /**
   * Create a report request
   */
  async fetch(reportType: ReportType, { params = {}, data = {} }: Options = {}) {
    const {
      reports: [report],
    } = await this.session.request<{ reports: [Report] }>(
      this.#url(reportType, {
        ...params,
        locale: this.defaultFormat.locale,
      }),
      {
        type: 'POST',
        data: this.#data(reportType, data),
        contentType: 'application/json',
      }
    );

    return report;
  }

  /**
   * Create and download a report
   */
  async download(reportType: ReportType, options: Options = {}) {
    const report = await this.fetch(reportType, options);

    // MediaDownloadRequests are async and send an email when complete
    if (reportType !== 'MediaDownloadRequests') {
      this.window.location.href = report.file_url;
    }
  }

  #data(reportType: ReportType, data: Options['data'] = {}) {
    if (reportType == 'MediaDownloadRequests') {
      return { media_download_requests: [{ should_send_email: true }] };
    }

    return {
      reports: [
        {
          report_type_id: this.enums.findWhere('REPORT_TYPE', { name: reportType }),
          ...data,
        },
      ],
    };
  }

  #url(reportType: ReportType, additionalParams: Options['params'] = {}) {
    const apiEndpoint = reportType === 'MediaDownloadRequests' ? 'media_download_requests' : 'reports';
    const params = new URLSearchParams({
      ...this.current.toSyndicatedParams(),
      ...additionalParams,
    });

    return `${endpoint(apiEndpoint) as string}?${params.toString()}`;
  }
}
